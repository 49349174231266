export const RUNTIME = {
  WEB: 'Web',
  WEEX: 'Weex',
  UNKNOWN: 'Unknown'
};

export const FRAMEWORK = {
  VUE: 'Vue',
  RAX: 'Rax',
  UNKNOWN: 'Unknown'
};